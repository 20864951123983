"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGamemodeSettings = exports.GamemodeSettingsContextProvider = exports.GamemodeSettingsContext = void 0;
const react_1 = require("react");
exports.GamemodeSettingsContext = (0, react_1.createContext)(null);
exports.GamemodeSettingsContext.displayName = "GamemodeSettingsContext";
exports.GamemodeSettingsContextProvider = exports.GamemodeSettingsContext.Provider;
const useGamemodeSettings = () => {
    const settings = (0, react_1.useContext)(exports.GamemodeSettingsContext);
    if (!settings) {
        throw new Error("No valid GamemodeSettingsContext found for useGamemodeSettings");
    }
    return settings;
};
exports.useGamemodeSettings = useGamemodeSettings;
