"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AVAILABLE_PROFILE_PICTURES = exports.MAX_NAME_LENGTH = exports.NAME_REGEX = void 0;
exports.NAME_REGEX = /^[a-zA-Z0-9\ ]*$/;
exports.MAX_NAME_LENGTH = 16;
exports.AVAILABLE_PROFILE_PICTURES = {
    1: "Budaye",
    4: "Aardorn",
    5: "Nut",
    7: "Embra",
    8: "Tweesher",
};
