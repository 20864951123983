"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLobbyPage = exports.LobbyPageContextProvider = exports.LobbyPageContext = void 0;
const react_1 = require("react");
exports.LobbyPageContext = (0, react_1.createContext)(null);
exports.LobbyPageContext.displayName = "LobbyContext";
exports.LobbyPageContextProvider = exports.LobbyPageContext.Provider;
const useLobbyPage = () => {
    const lobbyPage = (0, react_1.useContext)(exports.LobbyPageContext);
    if (!lobbyPage) {
        throw new Error("No valid LobbyPageContext found for useLobbyPage");
    }
    return lobbyPage;
};
exports.useLobbyPage = useLobbyPage;
