"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const client_1 = require("react-dom/client");
const config_1 = require("@cc-web/auth/auth0/config");
const context_1 = require("@cc-web/auth/context");
const app_1 = require("./app");
const Auth0AppRoot = ({ children }) => {
    const onRedirectCallback = React.useCallback((appState) => {
        window.location.href = (appState === null || appState === void 0 ? void 0 : appState.returnTo) || window.location.pathname;
    }, []);
    const Auth0AuthProvider = React.useMemo(
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    () => require("@cc-web/auth/auth0/provider").Auth0AuthProvider, []);
    return (React.createElement(Auth0AuthProvider, { onRedirectCallback: onRedirectCallback }, children));
};
const AppRoot = () => {
    if (config_1.AUTH0_ENABLED) {
        return (React.createElement(Auth0AppRoot, null,
            React.createElement(app_1.App, null)));
    }
    return (React.createElement(context_1.LocalPlayerContextProvider, { value: null },
        React.createElement(app_1.App, null)));
};
const container = document.getElementById("approot");
const root = (0, client_1.createRoot)(container);
root.render(React.createElement(AppRoot, null));
