"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rotatePiecesAboutCenter = exports.rotateGridPosition = void 0;
const selectors_1 = require("../selectors");
const rotateGridPosition = (gridSize, position) => ({
    x: gridSize.width - 1 - position.x,
    y: gridSize.height - 1 - position.y,
});
exports.rotateGridPosition = rotateGridPosition;
const rotatePiecesAboutCenter = (state) => {
    const newPositions = [];
    for (const [pieceId] of Object.entries(state.pieces)) {
        const position = (0, selectors_1.getPiecePosition)(state, pieceId);
        if (!position) {
            continue;
        }
        const newPosition = (0, exports.rotateGridPosition)(state.size, position);
        const newPositionKey = `${newPosition.x},${newPosition.y}`;
        newPositions.push({ pieceId, position: newPositionKey });
    }
    return Object.assign(Object.assign({}, state), { piecePositions: newPositions.reduce((acc, { pieceId, position }) => (Object.assign(Object.assign({}, acc), { [position]: pieceId })), {}) });
};
exports.rotatePiecesAboutCenter = rotatePiecesAboutCenter;
