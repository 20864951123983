"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auth0Config = exports.AUTH0_ENABLED = void 0;
const constants_1 = require("@cc-web/shared/constants");
exports.AUTH0_ENABLED = process.env.AUTH0_ENABLED === "true";
exports.auth0Config = {
    domain: process.env.AUTH0_DOMAIN,
    clientID: process.env.AUTH0_SPA_CLIENT_ID,
    redirectUri: constants_1.APP_BASE_URL,
    audience: `https://${process.env.AUTH0_DOMAIN}/api/v2/`,
    scope: "openid profile email",
};
