"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardShop = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const GamemodeSettingsContext_1 = require("../../GamemodeSettingsContext");
const layout_1 = require("../../layout");
const button_1 = require("../button");
const display_1 = require("../display");
const cardSelector_1 = require("./cardSelector");
const currentCard_1 = require("./currentCard");
const useStyles = (0, react_jss_1.createUseStyles)({
    container: {
        width: "100%",
        height: "100%",
    },
    grow: {
        flex: 1,
        marginBottom: "2em",
    },
    balance: {
        background: "#2f2f2f",
        padding: "0 1em",
        lineHeight: "1.5em",
    },
});
const CardShop = ({ cards, ownedDefinitionIds, money, isLocked = false, onReroll, onToggleLock, onBuy, showSelectedCard = true, }) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const onBuyCurrentCard = () => onBuy && selectedIndex !== null && onBuy(selectedIndex);
    const selectedCard = selectedIndex !== null ? cards[selectedIndex] : null;
    React.useEffect(() => {
        if (!selectedCard) {
            return;
        }
        const canAfford = selectedCard.cost <= money;
        if (!canAfford) {
            setSelectedIndex(null);
        }
    }, [selectedCard, money]);
    const { rerollCost } = (0, GamemodeSettingsContext_1.useGamemodeSettings)();
    return (React.createElement(layout_1.Layout, { className: classes.container, direction: "column" },
        React.createElement(layout_1.Layout, { direction: "column", justifyContent: "center", className: classes.grow }, selectedCard && showSelectedCard && (React.createElement(currentCard_1.CurrentCard, { card: selectedCard, alreadyOwned: ownedDefinitionIds.includes(selectedCard.definitionId), onBuy: onBuyCurrentCard }))),
        React.createElement(cardSelector_1.CardSelector, { cards: cards, money: money, selectedCardIndex: selectedIndex, onSelectCard: setSelectedIndex, ownedDefinitionIds: ownedDefinitionIds }),
        React.createElement(layout_1.Layout, { direction: "row", justifyContent: "space-between" },
            React.createElement(button_1.Button, { type: "primary", onClick: onReroll, disabled: money < rerollCost },
                "New ($",
                rerollCost,
                ")"),
            React.createElement("div", { className: classes.balance },
                React.createElement(display_1.Label, null, "Balance:"),
                " ",
                React.createElement(display_1.Label, { type: "highlight" },
                    "$",
                    money)),
            React.createElement(button_1.Button, { type: "primary", onClick: onToggleLock }, isLocked ? "Unlock" : "Lock (1 turn)"))));
};
exports.CardShop = CardShop;
