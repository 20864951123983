"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LobbyPage = void 0;
const React = __importStar(require("react"));
const Footer_1 = require("../../src/Footer");
const countdown_1 = require("../../src/countdown");
const LobbyPage_styles_1 = require("./LobbyPage.styles");
const LobbyPageContext_1 = require("./LobbyPageContext");
const LobbyPlayerBanner_1 = require("./LobbyPlayerBanner/LobbyPlayerBanner");
const SettingsMenu_1 = require("./settings/SettingsMenu");
const useOpenSettingsMenu_1 = require("./settings/useOpenSettingsMenu");
const padNumberToTwo = (val) => (val < 10 ? `0${val}` : val.toString());
const countdownRender = (styles) => (totalSecondsRemaining) => {
    const minutesRemaining = Math.floor(totalSecondsRemaining / 60);
    const secondsRemaining = Math.ceil(totalSecondsRemaining % 60);
    const time = `${minutesRemaining}:${padNumberToTwo(secondsRemaining)}`;
    return (React.createElement("div", { className: styles.timeRemaining },
        "Game starting in",
        " ",
        React.createElement("span", { className: styles.timeRemainingHighlight }, time)));
};
function LobbyPage() {
    const styles = (0, LobbyPage_styles_1.useStyles)();
    const { players, startingAtMs, maxPlayers, lobbyWaitTimeSeconds } = (0, LobbyPageContext_1.useLobbyPage)();
    const { targetRef: finalPlayerRef, menuOpen } = (0, useOpenSettingsMenu_1.useOpenSettingsMenu)();
    const playerItems = React.useMemo(() => {
        const output = [];
        for (let i = 0; i < maxPlayers; i++) {
            const player = players[i];
            output.push(React.createElement("div", { key: player ? player.id : i, className: styles.playerWrapper, ref: i === maxPlayers - 1 ? finalPlayerRef : undefined },
                React.createElement(LobbyPlayerBanner_1.LobbyPlayerBanner, { player: player !== null && player !== void 0 ? player : null })));
        }
        return output;
    }, [maxPlayers, players, styles.playerWrapper, finalPlayerRef]);
    return (React.createElement("div", { className: styles.lobbyPage },
        React.createElement("div", { className: styles.lobbyInfo },
            startingAtMs && (React.createElement(countdown_1.Countdown, { countdownToSeconds: startingAtMs / 1000, render: countdownRender(styles) })),
            React.createElement("div", { className: styles.players }, playerItems),
            React.createElement("p", null,
                "The game will start ",
                lobbyWaitTimeSeconds,
                " seconds after the lobby is created, or immediately when there are ",
                maxPlayers,
                " players"),
            menuOpen && React.createElement(SettingsMenu_1.SettingsMenu, null)),
        React.createElement(Footer_1.Footer, null)));
}
exports.LobbyPage = LobbyPage;
