"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LobbyPlayerBanner = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const constants_1 = require("@cc-web/shared/constants");
const avatar_1 = require("../../../src/player/avatar");
const title_1 = require("../../../src/player/title");
const useStyles = (0, react_jss_1.createUseStyles)({
    player: {
        padding: "0.4em 1em",
        marginBottom: "0.4em",
        fontWeight: "700",
        textAlign: "left",
        background: "#59616b",
        fontFamily: "Arial, Helvetica, sans-serif",
        color: "#fff",
        userSelect: "none",
    },
    nameAndImage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "stretch",
        marginBottom: "0.25rem",
    },
    bot: {
        fontStyle: "italic",
        color: "#d0d0d0",
    },
});
const NO_PLAYER_IMAGE = `${constants_1.IMAGE_BASE_URL}/ui/no_player_img.png`;
const LobbyPlayerBanner = ({ player }) => {
    var _a;
    const styles = useStyles();
    if (!player) {
        return (React.createElement("div", { className: `${styles.player} ${styles.bot}` },
            React.createElement("span", { className: styles.nameAndImage },
                React.createElement("img", { src: NO_PLAYER_IMAGE, alt: "no player image" }),
                React.createElement("div", null),
                "empty slot")));
    }
    return (React.createElement("div", { className: styles.player },
        React.createElement("span", { className: styles.nameAndImage },
            React.createElement(avatar_1.PlayerAvatar, { player: player }),
            React.createElement("div", null),
            player.name),
        React.createElement(title_1.Title, { title: (_a = player.profile) === null || _a === void 0 ? void 0 : _a.title })));
};
exports.LobbyPlayerBanner = LobbyPlayerBanner;
