"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickChatOption = void 0;
var QuickChatOption;
(function (QuickChatOption) {
    QuickChatOption["GL"] = "GL";
    QuickChatOption["HAPPY"] = "\uD83D\uDE03";
    QuickChatOption["SHOCKED"] = "\uD83D\uDE31";
    QuickChatOption["ANGRY"] = "\uD83D\uDE20";
})(QuickChatOption || (exports.QuickChatOption = QuickChatOption = {}));
