"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PieceMeta = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const layout_1 = require("../../layout");
const display_1 = require("../../src/display");
const PieceHealthbar_1 = require("./PieceHealthbar");
const PieceStageIndicator_1 = require("./PieceStageIndicator");
const useStyles = (0, react_jss_1.createUseStyles)({
    typeIndicatorContainer: {
        marginBottom: "0.25em",
        width: "100%",
    },
    healthbarContainer: {
        flex: 1,
        position: "relative",
    },
    stage: {
        "position": "absolute",
        "top": "10%",
        "left": "7%",
        "width": "86%",
        "height": "80%",
        "& > img": {
            width: "100%",
        },
    },
});
const PieceMeta = ({ piece, healthbarColor = "none", }) => {
    const classes = useStyles();
    const stageIndicator = (React.createElement("div", { className: classes.stage },
        React.createElement(PieceStageIndicator_1.PieceStageIndicator, { stage: piece.stage })));
    return (React.createElement(layout_1.Layout, { grow: true, direction: "column", noSpacer: true },
        React.createElement("div", { className: classes.typeIndicatorContainer },
            React.createElement(display_1.TypeIndicator, { type: piece.definition.type })),
        React.createElement("div", { className: classes.healthbarContainer },
            healthbarColor !== "none" && (React.createElement(PieceHealthbar_1.PieceHealthbar, { color: healthbarColor, current: piece.currentHealth, max: piece.maxHealth }, stageIndicator)),
            healthbarColor === "none" && stageIndicator)));
};
exports.PieceMeta = PieceMeta;
