"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateNicknameFormat = void 0;
const constants_1 = require("./constants");
const validateNicknameFormat = (nickname) => {
    if (!nickname || !nickname.length || nickname.length < 4) {
        return "Nickname must be at least 4 characters long";
    }
    if (nickname.length > constants_1.MAX_NAME_LENGTH) {
        return "Name too long";
    }
    if (nickname.match(constants_1.NAME_REGEX) === null) {
        return "Invalid characters in name";
    }
    return null;
};
exports.validateNicknameFormat = validateNicknameFormat;
