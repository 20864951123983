"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.finishedBattle = exports.inProgressBattle = exports.PlayerStatus = exports.PlayerBattleStatus = void 0;
var PlayerBattleStatus;
(function (PlayerBattleStatus) {
    PlayerBattleStatus[PlayerBattleStatus["IN_PROGRESS"] = 0] = "IN_PROGRESS";
    PlayerBattleStatus[PlayerBattleStatus["FINISHED"] = 1] = "FINISHED";
})(PlayerBattleStatus || (exports.PlayerBattleStatus = PlayerBattleStatus = {}));
var PlayerStatus;
(function (PlayerStatus) {
    PlayerStatus[PlayerStatus["CONNECTED"] = 0] = "CONNECTED";
    PlayerStatus[PlayerStatus["DEAD"] = 1] = "DEAD";
    PlayerStatus[PlayerStatus["QUIT"] = 2] = "QUIT";
})(PlayerStatus || (exports.PlayerStatus = PlayerStatus = {}));
const inProgressBattle = (opponentId) => ({
    status: PlayerBattleStatus.IN_PROGRESS,
    opponentId,
});
exports.inProgressBattle = inProgressBattle;
const finishedBattle = (opponentId, isHomePlayer, homeScore, awayScore) => ({
    status: PlayerBattleStatus.FINISHED,
    opponentId,
    isHomePlayer,
    homeScore,
    awayScore,
});
exports.finishedBattle = finishedBattle;
