"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const constants_1 = require("@cc-web/shared/constants");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const APP_VERSION = require("../../../../package.json").version;
const useStyles = (0, react_jss_1.createUseStyles)({
    footer: {
        marginTop: "0.5em",
        marginBottom: "0.5em",
        textAlign: "center",
    },
    item: {
        fontFamily: "Arial, sans-serif",
        fontSize: "0.8em",
        fontStyle: "italic",
        color: "#fff",
    },
});
const Footer = () => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.footer },
        React.createElement("span", { className: classes.item },
            "v",
            APP_VERSION || "-.-.-"),
        " - ",
        React.createElement("a", { className: classes.item, href: "https://reddit.com/r/creaturechess/" }, "/r/CreatureChess"),
        " - ",
        React.createElement("a", { className: classes.item, href: `${constants_1.APP_BASE_URL}privacy` }, "Privacy Policy"),
        " - ",
        React.createElement("a", { className: classes.item, href: "https://github.com/Jameskmonger/creature-chess" }, "Licenses on GitHub")));
};
exports.Footer = Footer;
