"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.topLeftToBottomRightSortPositions = exports.topToBottomMiddleSortPositions = void 0;
const SORT_A_FIRST = -1;
const SORT_A_SECOND = 1;
const topToBottomMiddleSortPositions = (a, b) => {
    if (a.y < b.y) {
        return SORT_A_FIRST;
    }
    if (a.y > b.y) {
        return SORT_A_SECOND;
    }
    // todo tie this into board size
    const distanceFromMiddleA = Math.abs(a.x - 3);
    const distanceFromMiddleB = Math.abs(b.x - 3);
    if (distanceFromMiddleA < distanceFromMiddleB) {
        return SORT_A_FIRST;
    }
    if (distanceFromMiddleA > distanceFromMiddleB) {
        return SORT_A_SECOND;
    }
    return SORT_A_FIRST;
};
exports.topToBottomMiddleSortPositions = topToBottomMiddleSortPositions;
const topLeftToBottomRightSortPositions = (a, b) => {
    if (a.y < b.y) {
        return SORT_A_FIRST;
    }
    if (a.y > b.y) {
        return SORT_A_SECOND;
    }
    if (a.x < b.x) {
        return SORT_A_FIRST;
    }
    if (a.x > b.x) {
        return SORT_A_SECOND;
    }
    return SORT_A_FIRST;
};
exports.topLeftToBottomRightSortPositions = topLeftToBottomRightSortPositions;
