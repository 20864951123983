"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstEmptySlot = exports.getPieceForPosition = exports.isBelowPieceLimit = exports.getPiece = exports.getAllPieces = exports.getPiecePosition = void 0;
const positionSort_1 = require("./positionSort");
// todo add a position-for-id lookup to the board state to improve this
const getPiecePosition = (state, pieceId) => {
    const entry = Object.entries(state.piecePositions).find(([_, id]) => id === pieceId);
    if (!entry) {
        return null;
    }
    const [x, y] = entry[0].split(",").map((val) => parseInt(val, 10));
    return { x, y };
};
exports.getPiecePosition = getPiecePosition;
const getAllPieces = (state) => Object.values(state.pieces).filter((p) => p !== null);
exports.getAllPieces = getAllPieces;
const getPiece = (state, pieceId) => state.pieces[pieceId] || null;
exports.getPiece = getPiece;
const isBelowPieceLimit = (state) => state.pieceLimit === null || (0, exports.getAllPieces)(state).length < state.pieceLimit;
exports.isBelowPieceLimit = isBelowPieceLimit;
const getPieceForPosition = (state, x, y) => state.pieces[state.piecePositions[`${x},${y}`]] || null;
exports.getPieceForPosition = getPieceForPosition;
const getFirstEmptySlot = (state, sortPositions = positionSort_1.topToBottomMiddleSortPositions) => {
    const emptyPositions = [];
    for (let y = 0; y < state.size.height; y++) {
        for (let x = 0; x < state.size.width; x++) {
            const boardPiece = (0, exports.getPieceForPosition)(state, x, y);
            if (!boardPiece) {
                emptyPositions.push({ x, y });
            }
        }
    }
    if (emptyPositions.length === 0) {
        return null;
    }
    emptyPositions.sort(sortPositions);
    const position = emptyPositions[0];
    return {
        x: position.x,
        y: position.y,
    };
};
exports.getFirstEmptySlot = getFirstEmptySlot;
