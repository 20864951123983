"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuPage = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const Footer_1 = require("../../src/Footer");
const MenuPage_styles_1 = require("./MenuPage.styles");
const MenuPageContext_1 = require("./MenuPageContext");
const useNavbarStyles = (0, react_jss_1.createUseStyles)({
    navbar: {
        "position": "fixed",
        "top": "0",
        "display": "flex",
        "flexDirection": "row",
        "width": "100%",
        "height": "2em",
        "background": "#985763",
        "justifyContent": "flex-end",
        "& button": {
            fontWeight: "700",
            color: "#fff",
            cursor: "pointer",
            background: "#b13e53",
            border: "none",
            borderLeft: "1px solid #fff",
        },
    },
});
const Navbar = () => {
    const styles = useNavbarStyles();
    const { auth: { logout }, } = (0, MenuPageContext_1.useMenuPage)();
    return (React.createElement("nav", { className: styles.navbar },
        React.createElement("button", { onClick: logout }, "Log Out")));
};
const MenuPage = ({ error }) => {
    const styles = (0, MenuPage_styles_1.useStyles)();
    const { findGame } = (0, MenuPageContext_1.useMenuPage)();
    return (React.createElement("div", { className: styles.menu },
        React.createElement(Navbar, null),
        React.createElement("div", { className: styles.joinGame },
            React.createElement("h2", null, "Creature Chess"),
            React.createElement("div", { className: styles.blurb },
                React.createElement("p", null, "More fun with friends! Press \"Find Game\" at the same time to play together"),
                React.createElement("p", null, "Up to 8 players!")),
            React.createElement("button", { onClick: findGame, className: styles.findGameButton }, "Find Game"),
            React.createElement("div", { className: styles.blurb },
                React.createElement("p", null, "Join us on Discord to receive notifications when someone starts a lobby, and more!")),
            React.createElement("a", { href: "https://discord.gg/FhMm6saehb" },
                React.createElement("img", { src: "https://i.imgur.com/OBo2QRd.png", className: styles.discordButton })),
            React.createElement("div", { className: styles.blurb },
                React.createElement("p", null,
                    "This is a ",
                    React.createElement("span", null, "multiplayer strategy game"),
                    " in which you configure creatures on a board."),
                React.createElement("p", null, "Each round, your board is matched against an opponent's board. Defeat all their pieces to win the round."),
                React.createElement("p", null, "Every loss decreases your health bar. When your health reaches zero, you're out!"),
                React.createElement("p", null, "Players will battle against eachother until only one player remains."),
                React.createElement("p", null,
                    "Good luck! ",
                    React.createElement("span", null, "~ jkm"))),
            error && (React.createElement("div", { className: styles.error },
                React.createElement("p", null, error)))),
        React.createElement(Footer_1.Footer, null)));
};
exports.MenuPage = MenuPage;
