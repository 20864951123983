"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePiece = exports.PieceContextProvider = void 0;
const react_1 = require("react");
const PieceContext = (0, react_1.createContext)(null);
PieceContext.displayName = "PieceContext";
exports.PieceContextProvider = PieceContext.Provider;
const usePiece = () => {
    const piece = (0, react_1.useContext)(PieceContext);
    if (!piece) {
        throw new Error("No valid PieceContext found for usePiece");
    }
    return piece;
};
exports.usePiece = usePiece;
