"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMenuPage = exports.MenuPageContextProvider = void 0;
const react_1 = require("react");
const MenuPageContext = (0, react_1.createContext)(null);
MenuPageContext.displayName = "MenuPageContext";
exports.MenuPageContextProvider = MenuPageContext.Provider;
const useMenuPage = () => {
    const menuPage = (0, react_1.useContext)(MenuPageContext);
    if (!menuPage) {
        throw new Error("No valid MenuPageContext found for useMenuPage");
    }
    return menuPage;
};
exports.useMenuPage = useMenuPage;
