"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOpenSettingsMenu = void 0;
const react_1 = require("react");
const hooks_1 = require("../../../hooks");
const CLICK_COUNT_REQUIRED = 3;
/**
 * Hook to open the settings menu when the user clicks on some target element
 * a certain number of times.
 *
 * @returns An object containing the ref of the target element and a boolean
 * indicating whether the menu is open.
 */
function useOpenSettingsMenu() {
    const [menuOpen, setMenuOpen] = (0, react_1.useState)(false);
    const [clickCount, setClickCount] = (0, react_1.useState)(0);
    const targetRef = (0, react_1.useRef)(null);
    const onTargetClick = (0, react_1.useCallback)(() => {
        if (menuOpen) {
            return;
        }
        setClickCount((prev) => prev + 1);
    }, [menuOpen]);
    const onTargetClickOutside = (0, react_1.useCallback)(() => {
        if (menuOpen) {
            return;
        }
        setClickCount(0);
    }, [menuOpen]);
    (0, react_1.useEffect)(() => {
        if (clickCount >= CLICK_COUNT_REQUIRED) {
            setMenuOpen(true);
        }
    }, [clickCount]);
    (0, react_1.useEffect)(() => {
        const div = targetRef.current;
        div === null || div === void 0 ? void 0 : div.addEventListener("click", onTargetClick);
        return () => {
            div === null || div === void 0 ? void 0 : div.removeEventListener("click", onTargetClick);
        };
    }, [targetRef, onTargetClick]);
    (0, hooks_1.useOnClickOutside)(targetRef, onTargetClickOutside);
    return (0, react_1.useMemo)(() => ({
        targetRef,
        menuOpen,
    }), [targetRef, menuOpen]);
}
exports.useOpenSettingsMenu = useOpenSettingsMenu;
