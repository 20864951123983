"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGlobalStyles = void 0;
const react_jss_1 = require("react-jss");
const useResetStyles = (0, react_jss_1.createUseStyles)({
    "@global": {
        ["html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p," +
            "blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em," +
            "img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i," +
            "center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption," +
            "tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure," +
            "figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary," +
            "time, mark, audio, video"]: {
            padding: "0",
            margin: "0",
            font: "inherit",
            fontSize: "100%",
            verticalAlign: "baseline",
            border: "0",
        },
        "article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section": {
            display: "block",
        },
        "body": {
            lineHeight: "1",
        },
        "ol, ul": {
            listStyle: "none",
        },
        "blockquote, q": {
            quotes: "none",
        },
        "blockquote::before, blockquote::after, q::before, q::after": {
            content: "none",
        },
        "table": {
            borderSpacing: "0",
            borderCollapse: "collapse",
        },
    },
});
const useGlobalGameStyles = (0, react_jss_1.createUseStyles)({
    "@global": {
        "*": {
            userSelect: "none",
        },
        ".approot": {
            position: "fixed",
            width: "100vw",
            height: "100vh",
        },
        "body": {
            background: "#333c57",
        },
    },
});
const useGlobalStyles = () => {
    useResetStyles();
    useGlobalGameStyles();
};
exports.useGlobalStyles = useGlobalStyles;
