"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentUser = void 0;
const CURRENT_USER_ENDPOINT = `${process.env.API_INFO_URL}/user/current`;
const getCurrentUser = (token) => fetch(CURRENT_USER_ENDPOINT, {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        "Authorization": token,
    },
});
exports.getCurrentUser = getCurrentUser;
