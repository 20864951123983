"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreakIndicator = void 0;
const react_1 = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const player_1 = require("@creature-chess/models/player");
const getBackground = (type) => type === player_1.StreakType.WIN ? "#38b764" : "#b13e53";
const useStyles = (0, react_jss_1.createUseStyles)({
    indicator: (props) => ({
        position: "relative",
        width: "1.2em",
        height: "1.2em",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: getBackground(props.type),
        border: "2px solid darkgray",
        boxShadow: "1px 1px 3px #888888",
    }),
    amount: {
        position: "absolute",
        top: "52%",
        fontFamily: "Arial, sans-serif",
        fontSize: "0.8rem",
        lineHeight: 0,
        color: "#fff",
    },
    sheen: {
        position: "absolute",
        top: "-100%",
        left: "-125%",
        width: "80%",
        height: "200%",
        background: "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.6), rgba(255,255,255,0))", // 3D sheen effect
        transform: "rotate(45deg)",
        transition: "left 1s ease-in-out, top 1s ease-in-out",
    },
});
function StreakIndicator(props) {
    const classes = useStyles(props);
    const sheenRef = (0, react_1.useRef)(null);
    // apply sheen effect when streak amount changes
    (0, react_1.useEffect)(() => {
        if (sheenRef.current) {
            sheenRef.current.style.transition = "none";
            sheenRef.current.style.left = "-100%";
            sheenRef.current.style.top = "-125%";
            // Force a reflow to reset the transition
            void sheenRef.current.offsetWidth;
            sheenRef.current.style.transition =
                "left 1s ease-in-out, top 1s ease-in-out";
            sheenRef.current.style.left = "100%";
            sheenRef.current.style.top = "75%";
            const timer = setTimeout(() => {
                if (sheenRef.current) {
                    sheenRef.current.style.transition = "none";
                    sheenRef.current.style.left = "-100%";
                    sheenRef.current.style.top = "-125%";
                }
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [props.amount]);
    if (props.type === null || !props.amount || props.amount === 1) {
        return null;
    }
    return (react_1.default.createElement("div", { className: classes.indicator },
        react_1.default.createElement("div", { className: classes.sheen, ref: sheenRef }),
        react_1.default.createElement("span", { className: classes.amount }, props.amount)));
}
exports.StreakIndicator = StreakIndicator;
