"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGameBoard = exports.GameBoardContextProvider = void 0;
const react_1 = require("react");
const GameBoardContext = (0, react_1.createContext)(null);
GameBoardContext.displayName = "GameBoardContext";
exports.GameBoardContextProvider = GameBoardContext.Provider;
const useGameBoard = () => {
    const GameBoard = (0, react_1.useContext)(GameBoardContext);
    if (!GameBoard) {
        throw new Error("No valid GameBoardContext found for useGameBoard");
    }
    return GameBoard;
};
exports.useGameBoard = useGameBoard;
