"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PieceHealthbar = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const display_1 = require("../../src/display");
const getFillBackground = ({ color }) => {
    if (color === "spectating") {
        return "#601a4a";
    }
    if (color === "enemy") {
        return "#e03c71";
    }
    return "#3887ca";
};
const useStyles = (0, react_jss_1.createUseStyles)({
    fill: {
        background: getFillBackground,
        position: "absolute",
        bottom: 0,
    },
});
const PieceHealthbar = (props) => {
    const classes = useStyles(props);
    return (React.createElement(display_1.ProgressBar, { fillClassName: classes.fill, current: props.current, max: props.max, vertical: true }, props.children));
};
exports.PieceHealthbar = PieceHealthbar;
