"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TileType = exports.getRelativeDirection = exports.Directions = exports.getDistance = exports.getDelta = exports.createTileCoordinates = void 0;
const createTileCoordinates = (x, y) => ({ x, y });
exports.createTileCoordinates = createTileCoordinates;
const getDelta = (a, b) => ({
    x: Math.abs(a.x - b.x),
    y: Math.abs(a.y - b.y),
});
exports.getDelta = getDelta;
const getDistance = (a, b) => {
    const { x, y } = (0, exports.getDelta)(a, b);
    return x + y;
};
exports.getDistance = getDistance;
exports.Directions = {
    UP: { x: 0, y: -1 },
    RIGHT: { x: 1, y: 0 },
    DOWN: { x: 0, y: 1 },
    LEFT: { x: -1, y: 0 },
};
/**
 * Returns the relative direction of position b from the perspective of position a
 *
 * @param from The position to find the direction relative from
 * @param to The position to find the direction relative to
 */
const getRelativeDirection = (from, to) => {
    if (from.x < to.x) {
        return exports.Directions.RIGHT;
    }
    if (from.x > to.x) {
        return exports.Directions.LEFT;
    }
    if (from.y < to.y) {
        return exports.Directions.DOWN;
    }
    if (from.y > to.y) {
        return exports.Directions.UP;
    }
    return { x: 0, y: 0 };
};
exports.getRelativeDirection = getRelativeDirection;
var TileType;
(function (TileType) {
    TileType[TileType["BOARD"] = 0] = "BOARD";
    TileType[TileType["BENCH"] = 1] = "BENCH";
})(TileType || (exports.TileType = TileType = {}));
