"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPiecePositionsWithoutIds = exports.getPiecesWithoutIds = void 0;
const getPiecesWithoutIds = (pieces, ids) => {
    const newPieces = Object.assign({}, pieces);
    for (const pieceId of ids) {
        if (newPieces[pieceId]) {
            delete newPieces[pieceId];
        }
    }
    return newPieces;
};
exports.getPiecesWithoutIds = getPiecesWithoutIds;
const getPiecePositionsWithoutIds = (piecePositions, ids) => Object.entries(piecePositions).reduce((newPiecePositions, [position, pieceId]) => {
    // skip the desired piece
    if (!pieceId || ids.includes(pieceId)) {
        return newPiecePositions;
    }
    newPiecePositions[position] = pieceId;
    return newPiecePositions;
}, {});
exports.getPiecePositionsWithoutIds = getPiecePositionsWithoutIds;
