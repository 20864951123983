"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginPage = void 0;
const React = __importStar(require("react"));
const Footer_1 = require("../../../src/Footer");
const LoginPage_styles_1 = require("./LoginPage.styles");
const LoginPage = ({ auth0Enabled = false, isLoading = false, onSignInClick, onPlayAsGuestClick, }) => {
    const styles = (0, LoginPage_styles_1.useStyles)();
    const [loadingSignIn, setLoadingSignIn] = React.useState(false);
    const currentlyLoading = isLoading || loadingSignIn;
    const handleSignInClick = () => {
        if (!auth0Enabled || currentlyLoading) {
            return;
        }
        setLoadingSignIn(true);
        if (onSignInClick) {
            onSignInClick();
        }
    };
    const handlePlayAsGuestClick = () => {
        if (onPlayAsGuestClick) {
            onPlayAsGuestClick();
        }
    };
    if (currentlyLoading) {
        return React.createElement("span", null, "Loading");
    }
    return (React.createElement("div", { className: styles.login },
        React.createElement("div", { className: styles.banner },
            React.createElement("img", { src: "https://i.imgur.com/7FAcFwZ.png" })),
        React.createElement("div", { className: styles.groups },
            React.createElement("div", { className: "group" },
                React.createElement("p", null, "Creature Chess is a multiplayer auto-chess battler"),
                auth0Enabled && (React.createElement(React.Fragment, null,
                    React.createElement("button", { onClick: handleSignInClick, className: styles.loginButton }, "Sign In"),
                    React.createElement("button", { onClick: handleSignInClick, className: styles.loginButton }, "Create Account"))),
                React.createElement("button", { onClick: handlePlayAsGuestClick, className: styles.guestButton }, "Play Now as Guest"),
                React.createElement("p", null, "Join us on Discord to find other players and give feedback on the game"),
                React.createElement("a", { href: "https://discord.gg/FhMm6saehb" },
                    React.createElement("img", { src: "https://i.imgur.com/OBo2QRd.png", className: styles.discordButton })))),
        React.createElement(Footer_1.Footer, null)));
};
exports.LoginPage = LoginPage;
