"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
// todo implement primary style
const getColor = (style) => style === "primary" ? "#fff" : "#fff";
const getBackground = (style) => style === "primary" ? "#1a1c2c" : "#1a1c2c";
const useStyles = (0, react_jss_1.createUseStyles)({
    button: (props) => ({
        "padding": "0.25em 1em",
        "fontSize": "1em",
        "color": getColor(props.type),
        "background": getBackground(props.type),
        "border": "none",
        "cursor": "pointer",
        "&:disabled": {
            background: "#575758",
            cursor: "not-allowed",
        },
    }),
});
const Button = (props) => {
    const classes = useStyles(props);
    const { onClick, disabled = false, children } = props;
    return (React.createElement("button", { className: classes.button, onClick: !disabled ? onClick : undefined, disabled: disabled }, children));
};
exports.Button = Button;
