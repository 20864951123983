"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Projectile = exports.usePiece = exports.PieceContextProvider = exports.Piece = void 0;
var Piece_1 = require("./Piece");
Object.defineProperty(exports, "Piece", { enumerable: true, get: function () { return Piece_1.Piece; } });
var PieceContext_1 = require("./PieceContext");
Object.defineProperty(exports, "PieceContextProvider", { enumerable: true, get: function () { return PieceContext_1.PieceContextProvider; } });
Object.defineProperty(exports, "usePiece", { enumerable: true, get: function () { return PieceContext_1.usePiece; } });
__exportStar(require("./match"), exports);
var Projectile_1 = require("./Projectile");
Object.defineProperty(exports, "Projectile", { enumerable: true, get: function () { return Projectile_1.Projectile; } });
