"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeIndicator = void 0;
const React = __importStar(require("react"));
const models_1 = require("@creature-chess/models");
const constants_1 = require("@cc-web/shared/constants");
function getIconUrl(iconName) {
    return `${constants_1.IMAGE_BASE_URL}/ui/${iconName}`;
}
const ICON_FOR_TYPE = {
    [models_1.CreatureType.Fire]: getIconUrl("type-fire.svg"),
    [models_1.CreatureType.Earth]: getIconUrl("type-earth.svg"),
    [models_1.CreatureType.Metal]: getIconUrl("type-metal.svg"),
    [models_1.CreatureType.Water]: getIconUrl("type-water.svg"),
    [models_1.CreatureType.Wood]: getIconUrl("type-wood.svg"),
};
const TypeIndicator = ({ type, }) => React.createElement("img", { src: ICON_FOR_TYPE[type] });
exports.TypeIndicator = TypeIndicator;
