"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeBoards = void 0;
const rotateGridPosition_1 = require("./rotateGridPosition");
const expandBoard = (board, { width, height }) => {
    const differenceWidth = width - board.size.width;
    const differenceHeight = height - board.size.height;
    return Object.assign(Object.assign({}, board), { size: { width, height }, piecePositions: Object.entries(board.piecePositions).reduce((newPiecePositions, [position, pieceId]) => {
            const [x, y] = position.split(",").map((val) => parseInt(val, 10));
            const newX = x + differenceWidth;
            const newY = y + differenceHeight;
            return Object.assign(Object.assign({}, newPiecePositions), { [`${newX},${newY}`]: pieceId });
        }, {}) });
};
const mergeBoards = (id, home, away) => {
    if (home.size.width !== away.size.width ||
        home.size.height !== away.size.height) {
        throw Error("Trying to merge odd-sized boards");
    }
    const newSize = {
        width: home.size.width,
        height: home.size.height * 2,
    };
    const expandedHome = expandBoard(home, newSize);
    const expandedAway = expandBoard(away, newSize);
    const rotatedAway = (0, rotateGridPosition_1.rotatePiecesAboutCenter)(expandedAway);
    return {
        id,
        pieces: Object.assign(Object.assign({}, expandedHome.pieces), rotatedAway.pieces),
        piecePositions: Object.assign(Object.assign({}, expandedHome.piecePositions), rotatedAway.piecePositions),
        locked: true,
        pieceLimit: null,
        size: newSize,
    };
};
exports.mergeBoards = mergeBoards;
