"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildCard = exports.buildPieceModel = exports.buildDefinition = void 0;
const creatureDefinition_1 = require("../creatureDefinition");
const creatureType_1 = require("../creatureType");
const buildDefinition = (definition = {}) => (Object.assign({ id: 1, name: "Creature", type: creatureType_1.CreatureType.Earth, class: creatureDefinition_1.DefinitionClass.ARCANE, cost: 1, stages: [] }, definition));
exports.buildDefinition = buildDefinition;
const buildPieceModel = (piece = {}) => (Object.assign({ id: "123", ownerId: "abc", definitionId: 1, definition: (0, exports.buildDefinition)(), facingAway: false, maxHealth: 100, currentHealth: 100, stage: 0 }, piece));
exports.buildPieceModel = buildPieceModel;
const buildCard = (card = {}) => (Object.assign({ id: "123", name: "Card", definitionId: 1, type: creatureType_1.CreatureType.Metal, class: creatureDefinition_1.DefinitionClass.ARCANE, cost: 1 }, card));
exports.buildCard = buildCard;
