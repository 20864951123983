"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PIECES_TO_EVOLVE = exports.MAX_HEALTH = exports.MAX_LEVEL = exports.GAME_PHASE_LENGTHS = void 0;
const game_phase_1 = require("./src/game-phase");
exports.GAME_PHASE_LENGTHS = {
    [game_phase_1.GamePhase.PREPARING]: 40,
    [game_phase_1.GamePhase.READY]: 3,
    [game_phase_1.GamePhase.PLAYING]: 35,
};
exports.MAX_LEVEL = 10;
exports.MAX_HEALTH = 100;
exports.PIECES_TO_EVOLVE = 3;
