"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocalPlayerId = exports.useLocalPlayer = exports.LocalPlayerContextProvider = exports.LocalPlayerContext = void 0;
const react_1 = require("react");
exports.LocalPlayerContext = (0, react_1.createContext)(null);
exports.LocalPlayerContext.displayName = "LocalPlayerContext";
exports.LocalPlayerContextProvider = exports.LocalPlayerContext.Provider;
const useLocalPlayer = () => (0, react_1.useContext)(exports.LocalPlayerContext);
exports.useLocalPlayer = useLocalPlayer;
const useLocalPlayerId = () => { var _a; return ((_a = (0, exports.useLocalPlayer)()) === null || _a === void 0 ? void 0 : _a.id) || ""; };
exports.useLocalPlayerId = useLocalPlayerId;
