"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatureImage = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const constants_1 = require("@cc-web/shared/constants");
const useStyles = (0, react_jss_1.createUseStyles)({
    image: {
        width: "100%",
        height: "100%",
        imageRendering: "pixelated",
        fallbacks: [
            {
                imageRendering: "optimize-contrast",
            },
            {
                imageRendering: "-webkit-optimize-contrast",
            },
            {
                imageRendering: "-o-crisp-edges",
            },
            {
                imageRendering: "-moz-crisp-edges",
            },
            {
                imageRendering: "optimizeSpeed",
            },
        ],
        msInterpolationMode: "nearest-neighbor",
    },
});
function getCreatureUrl(facing, definitionId) {
    return `${constants_1.IMAGE_BASE_URL}/creatures/${facing}/${definitionId}.png`;
}
function CreatureImage({ facing, definitionId }) {
    return (React.createElement("img", { className: useStyles().image, src: getCreatureUrl(facing || "front", definitionId) }));
}
exports.CreatureImage = CreatureImage;
