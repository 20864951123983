"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationPage = void 0;
const React = __importStar(require("react"));
const profile_1 = require("@creature-chess/user/profile");
const RegistrationPage_styles_1 = require("./RegistrationPage.styles");
const NicknameSelection_1 = require("./input/NicknameSelection");
const PictureSelection_1 = require("./input/PictureSelection");
function RegistrationPage({ updateUser }) {
    const styles = (0, RegistrationPage_styles_1.useStyles)();
    const [nickname, setNickname] = React.useState("");
    const [currentImage, setCurrentImage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const handleNameChange = (event) => {
        if (loading) {
            return;
        }
        setNickname(event.target.value);
    };
    const handleImageChange = (picture) => {
        if (loading) {
            return;
        }
        setCurrentImage(picture);
    };
    const onRegisterClick = () => __awaiter(this, void 0, void 0, function* () {
        const nicknameError = (0, profile_1.validateNicknameFormat)(nickname);
        if (nicknameError) {
            setError(nicknameError);
            return;
        }
        setLoading(true);
        const { error: responseError } = yield updateUser(nickname, currentImage);
        if (!responseError) {
            return;
        }
        setLoading(false);
        setError(responseError);
    });
    return (React.createElement("div", { className: styles.register },
        React.createElement("h1", null, "Registration"),
        error && React.createElement("p", { className: styles.error }, error),
        React.createElement(NicknameSelection_1.NicknameSelection, { nickname: nickname || "", maxLength: profile_1.MAX_NAME_LENGTH, onChange: handleNameChange, loading: loading }),
        React.createElement(PictureSelection_1.PictureSelection, { currentImage: currentImage, onChange: handleImageChange }),
        React.createElement("button", { className: styles.registerButton, onClick: onRegisterClick, disabled: loading },
            !loading && "Register",
            loading && "Loading...")));
}
exports.RegistrationPage = RegistrationPage;
