"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Builders = exports.QuickChatOption = exports.CreatureType = exports.DefinitionClass = exports.attackTypes = exports.getRelativeDirection = exports.getDelta = exports.getDistance = exports.createTileCoordinates = exports.Directions = exports.TileType = exports.GamePhase = void 0;
var game_phase_1 = require("./src/game-phase");
Object.defineProperty(exports, "GamePhase", { enumerable: true, get: function () { return game_phase_1.GamePhase; } });
var position_1 = require("./src/position");
Object.defineProperty(exports, "TileType", { enumerable: true, get: function () { return position_1.TileType; } });
Object.defineProperty(exports, "Directions", { enumerable: true, get: function () { return position_1.Directions; } });
Object.defineProperty(exports, "createTileCoordinates", { enumerable: true, get: function () { return position_1.createTileCoordinates; } });
Object.defineProperty(exports, "getDistance", { enumerable: true, get: function () { return position_1.getDistance; } });
Object.defineProperty(exports, "getDelta", { enumerable: true, get: function () { return position_1.getDelta; } });
Object.defineProperty(exports, "getRelativeDirection", { enumerable: true, get: function () { return position_1.getRelativeDirection; } });
var creatureDefinition_1 = require("./src/creatureDefinition");
Object.defineProperty(exports, "attackTypes", { enumerable: true, get: function () { return creatureDefinition_1.attackTypes; } });
Object.defineProperty(exports, "DefinitionClass", { enumerable: true, get: function () { return creatureDefinition_1.DefinitionClass; } });
var creatureType_1 = require("./src/creatureType");
Object.defineProperty(exports, "CreatureType", { enumerable: true, get: function () { return creatureType_1.CreatureType; } });
var quickChat_1 = require("./src/quickChat");
Object.defineProperty(exports, "QuickChatOption", { enumerable: true, get: function () { return quickChat_1.QuickChatOption; } });
exports.Builders = __importStar(require("./src/builders"));
