"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.patchUser = void 0;
const CURRENT_USER_ENDPOINT = `${process.env.API_INFO_URL}/user/current`;
const patchUser = (token, nickname, picture) => fetch(CURRENT_USER_ENDPOINT, {
    method: "PATCH",
    headers: {
        "Content-Type": "application/json",
        "Authorization": token,
    },
    body: JSON.stringify({ nickname, picture }),
});
exports.patchUser = patchUser;
