"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerListItem = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const react_jss_1 = require("react-jss");
const hooks_1 = require("../../hooks");
const layout_1 = require("../../layout");
const button_1 = require("../button");
const display_1 = require("../display");
const player_1 = require("../player");
const battleInfo_1 = require("./battleInfo");
const streakIndicator_1 = require("./streakIndicator");
const getDetailReadyColor = ({ player: { ready }, showReadyIndicator = false, }) => (ready && showReadyIndicator ? "#20b720" : "#ccc");
const useStyles = (0, react_jss_1.createUseStyles)({
    container: (props) => ({
        "border": props.isOpponent ? "3px solid #b13e53" : "",
        "boxSizing": "border-box",
        "padding": "0.5rem",
        "background": "#566c86",
        "&:not(:last-child)": {
            marginBottom: "0.25em",
        },
    }),
    details: (props) => ({
        flex: 1,
        paddingLeft: "0.5em",
        borderLeft: `5px solid ${getDetailReadyColor(props)}`,
        display: "flex",
        flexDirection: "column",
    }),
    grow: {
        flex: 1,
    },
    battleContainer: {
        width: "100%",
    },
    badges: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0 0.25em",
        boxSizing: "border-box",
        alignItems: "center",
    },
});
const PlayerListItem = (props) => {
    const styles = useStyles(props);
    const { index, player, opponentName, isLocal, onSpectateClick, currentlySpectating = false, } = props;
    const ref = (0, react_1.useRef)(null);
    const [isExpanded, setIsExpanded] = (0, react_1.useState)(false);
    (0, hooks_1.useOnClickOutside)(ref, () => setIsExpanded(false));
    const toggleExpanded = () => {
        // don't open for local player
        if (isLocal) {
            return;
        }
        setIsExpanded(!isExpanded);
    };
    return (React.createElement("div", { className: styles.container, onClick: toggleExpanded, ref: ref },
        React.createElement(layout_1.Layout, { direction: "row", noSpacer: true },
            React.createElement(player_1.PlayerAvatar, { player: player }),
            React.createElement("div", { className: styles.details },
                React.createElement(layout_1.Layout, { direction: "row", noSpacer: true },
                    React.createElement(layout_1.Half, null,
                        React.createElement(player_1.PlayerProfile, { position: index + 1, player: player, isLocal: isLocal })),
                    React.createElement(layout_1.Half, null,
                        React.createElement(player_1.PlayerHealthbar, { health: player.health }))),
                React.createElement(layout_1.Layout, { direction: "row", className: styles.grow },
                    React.createElement(layout_1.Half, { className: styles.badges },
                        React.createElement(streakIndicator_1.StreakIndicator, { type: player.streakType, amount: player.streakAmount }),
                        React.createElement(display_1.Label, { type: "highlight" },
                            "$",
                            player.money),
                        React.createElement(display_1.Label, null,
                            "Lv ",
                            player.level)),
                    React.createElement(layout_1.Half, null, currentlySpectating || isExpanded ? (React.createElement(button_1.Button, { onClick: onSpectateClick }, currentlySpectating ? "Stop spectating" : "Spectate")) : (React.createElement(layout_1.Layout, { direction: "row", noSpacer: true, justifyContent: "center", className: styles.battleContainer },
                        React.createElement(battleInfo_1.BattleInfo, { battle: player.battle, opponentName: opponentName })))))))));
};
exports.PlayerListItem = PlayerListItem;
