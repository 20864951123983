"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPlayerListItem = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const display_1 = require("../display");
const battleInfo_1 = require("./battleInfo");
const useStyles = (0, react_jss_1.createUseStyles)({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        boxSizing: "border-box",
        padding: "0.4rem",
        marginBottom: "0.25em",
        fontFamily: "Arial, sans-serif",
        fontSize: "1.2em",
        fontWeight: "700",
        color: "#fff",
        textAlign: "center",
        textTransform: "uppercase",
        background: "#3b3d40",
    },
    // todo make this into a component
    col: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "space-around",
    },
    subtitle: {
        padding: "0.5em 0",
        marginTop: "0.5em",
        fontFamily: "Arial, sans-serif",
        fontSize: "0.9em",
        fontWeight: 700,
        color: "#847878",
        textAlign: "center",
        textTransform: "uppercase",
        borderTop: "2px solid #847878",
        borderBottom: "2px solid #847878",
    },
    name: {
        fontSize: "1.8em",
        fontStyle: "italic",
        color: "#cecece",
    },
});
const StatusPlayerListItem = ({ name, opponentName, battle, status, subtitle, }) => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.col },
            React.createElement("span", { className: classes.name }, name)),
        React.createElement("div", { className: classes.col },
            React.createElement(display_1.Label, null, status),
            React.createElement(battleInfo_1.BattleInfo, { battle: battle, opponentName: opponentName }),
            subtitle && React.createElement("span", { className: classes.subtitle }, subtitle))));
};
exports.StatusPlayerListItem = StatusPlayerListItem;
