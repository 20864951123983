"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBoardSlice = exports.createInitialBoardState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const filter_1 = require("./utils/filter");
const createInitialBoardState = (id, size = {
    width: 7,
    height: 3,
}) => ({
    id,
    pieces: {},
    piecePositions: {},
    locked: false,
    pieceLimit: null,
    size,
});
exports.createInitialBoardState = createInitialBoardState;
const createBoardSlice = (id, size) => {
    const { reducer, actions: { setBoardSizeCommand, lockBoardCommand, unlockBoardCommand, setPieceLimitCommand, setBoardPiecesCommand, addBoardPieceCommand, moveBoardPieceCommand, removeBoardPiecesCommand, updateBoardPiecesCommand, swapPiecesCommand, }, } = (0, toolkit_1.createSlice)({
        name: `board-${id}`,
        initialState: (0, exports.createInitialBoardState)(id, size),
        reducers: {
            setBoardSizeCommand: (state, { payload: { width, height }, }) => {
                const differenceWidth = width - state.size.width;
                const differenceHeight = height - state.size.height;
                return Object.assign(Object.assign({}, state), { size: { width, height }, piecePositions: Object.entries(state.piecePositions).reduce((newPiecePositions, [position, pieceId]) => {
                        const [x, y] = position.split(",").map((val) => parseInt(val, 10));
                        const newX = x + differenceWidth;
                        const newY = y + differenceHeight;
                        return Object.assign(Object.assign({}, newPiecePositions), { [`${newX},${newY}`]: pieceId });
                    }, {}) });
            },
            lockBoardCommand: (state) => (Object.assign(Object.assign({}, state), { locked: true })),
            unlockBoardCommand: (state) => (Object.assign(Object.assign({}, state), { locked: false })),
            setPieceLimitCommand: (state, { payload: limit }) => (Object.assign(Object.assign({}, state), { pieceLimit: limit })),
            // TODO (jkm) remove `size` from here
            setBoardPiecesCommand: (state, { payload: { pieces, piecePositions, size: newSize }, }) => (Object.assign(Object.assign(Object.assign({}, state), { pieces: Object.assign({}, pieces), piecePositions: Object.assign({}, piecePositions) }), (newSize
                ? { size: { width: newSize.width, height: newSize.height } }
                : {}))),
            addBoardPieceCommand: (state, { payload: { x, y, piece }, }) => (Object.assign(Object.assign({}, state), { pieces: Object.assign(Object.assign({}, state.pieces), { [piece.id]: piece }), piecePositions: Object.assign(Object.assign({}, state.piecePositions), { [`${x},${y}`]: piece.id }) })),
            moveBoardPieceCommand: (state, { payload: { pieceId, from, to }, }) => {
                const piece = state.pieces[pieceId];
                const fromString = `${from.x},${from.y}`;
                const pieceAtFrom = state.piecePositions[fromString];
                // safety catch
                if (!piece || piece.id !== pieceId || piece.id !== pieceAtFrom) {
                    return state;
                }
                const toString = `${to.x},${to.y}`;
                const newState = Object.assign(Object.assign({}, state), { piecePositions: Object.assign(Object.assign({}, state.piecePositions), { [toString]: pieceId }) });
                delete newState.piecePositions[fromString];
                return newState;
            },
            removeBoardPiecesCommand: (state, { payload: pieceIds }) => (Object.assign(Object.assign({}, state), { pieces: (0, filter_1.getPiecesWithoutIds)(state.pieces, pieceIds), piecePositions: (0, filter_1.getPiecePositionsWithoutIds)(state.piecePositions, pieceIds) })),
            updateBoardPiecesCommand: (state, { payload: pieces }) => {
                const newPieces = Object.assign({}, state.pieces);
                for (const piece of pieces) {
                    newPieces[piece.id] = piece;
                }
                return Object.assign(Object.assign({}, state), { pieces: newPieces });
            },
            swapPiecesCommand: (state, { payload: { aId, bId } }) => {
                if (!aId || !bId) {
                    return state;
                }
                const a = state.pieces[aId];
                const b = state.pieces[bId];
                if (!a || !b) {
                    return state;
                }
                const positions = Object.entries(state.piecePositions);
                const aPositionEntry = positions.find(([, pieceId]) => pieceId === aId);
                const bPositionEntry = positions.find(([, pieceId]) => pieceId === bId);
                if (!aPositionEntry || !bPositionEntry) {
                    return state;
                }
                const [aPosition] = aPositionEntry;
                const [bPosition] = bPositionEntry;
                return Object.assign(Object.assign({}, state), { piecePositions: Object.assign(Object.assign({}, state.piecePositions), { [aPosition]: b.id, [bPosition]: a.id }) });
            },
        },
    });
    return {
        boardReducer: reducer,
        commands: {
            setBoardSizeCommand,
            lockBoardCommand,
            unlockBoardCommand,
            setPieceLimitCommand,
            setBoardPiecesCommand,
            addBoardPieceCommand,
            moveBoardPieceCommand,
            removeBoardPiecesCommand,
            updateBoardPiecesCommand,
            swapPiecesCommand,
        },
    };
};
exports.createBoardSlice = createBoardSlice;
